export default class Kanji {
  static isKanji(char: string): boolean {
    if (char.charCodeAt(0) >= 19968 && char.charCodeAt(0) < 40960) {
      return true;
    }
    return false;
  }

  static isHira(char: string): boolean {
    if (char.charCodeAt(0) >= 12353 && char.charCodeAt(0) < 12436) {
      return true;
    }
    return false;
  }

  static isKata(char: string): boolean {
    if (char.charCodeAt(0) >= 12449 && char.charCodeAt(0) < 12538) {
      return true;
    }
    return false;
  }

  static isKHK(char: string): boolean {
    if (Kanji.isKanji(char) || Kanji.isHira(char) || Kanji.isKata(char)) {
      return true;
    }
    return false;
  }

  static onlyKHK(str: string): string {
    let newStr = '';
    for (let i = 0; i < str.length; i += 1) {
      if (Kanji.isKHK(str[i])) {
        newStr += str[i];
      }
    }
    return newStr;
  }
}
