import Meaning from '@/classes/Meaning';

export default class MeaningList {
  languages: Array<Meaning> = [];

  avaiLang: Array<string> = [...MeaningList.languagesList];

  constructor(translations: Array<Meaning> = []) {
    for (let i = 0; i < translations.length; i += 1) {
      this.add(translations[i]);
    }
  }

  add(ln: Meaning): void {
    for (let i = 0; i < this.avaiLang.length; i += 1) {
      if (ln.name === this.avaiLang[i]) {
        this.languages.push(ln);
        this.avaiLang.splice(i, 1);
      }
    }
  }

  addDefault(): void {
    if (this.avaiLang.length > 0) {
      this.add(new Meaning(this.avaiLang[0], ['', '', '']));
    }
  }

  remove(index: number): void {
    if (index < this.languages.length) {
      this.avaiLang.push(this.languages[index].name);
      this.languages.splice(index, 1);
    }
  }

  static languagesList = [
    'EN',
    'FR',
  ];
}
