import Mono from '@/classes/Mono';
import MeaningList from '@/classes/MeaningList';
import Kanji from '@/classes/Kanji';

export default class Kotoba {
  kotoba: Array<Mono> = [];

  kanjiList: Array<Mono> = [];

  prevString = '';

  meaning: MeaningList = new MeaningList();

  getStr(): string {
    let word = '';
    for (let i = 0; i < this.kotoba.length; i += 1) {
      word += this.kotoba[i].kanji ? this.kotoba[i].kanji : this.kotoba[i].furi;
    }
    return word;
  }

  setFromStr(input: string): void {
    const str = Kanji.onlyKHK(input);
    if (this.prevString !== str) {
      const prevKanji = [...this.kanjiList];

      this.setKotobaFromStr(input);

      if (prevKanji.length > 0) {
        for (let i = 0; i < this.kanjiList.length; i += 1) {
          let found = false;
          for (let y = 0; y < prevKanji.length && !found; y += 1) {
            if (this.kanjiList[i].kanji === prevKanji[y].kanji) {
              this.kanjiList[i].furi = prevKanji[y].furi; // Asign old furi to new
              prevKanji.splice(y, 1); // Remove from old array
              found = true;
            }
          }
        }
      }
      this.prevString = str;
    }
  }

  setKotobaFromStr(str: string, furiList?: Array<string>, trucquejaijamaisecr?: MeaningList): void {
    this.kotoba = []; // Remove all from array
    this.kanjiList = [];

    let next = str.length > 1 ? Kanji.isKanji(str[1]) : false; // realName: nextIsKanji
    let current = str.length > 0 ? Kanji.isKanji(str[0]) : false; // realName: currentIsKanji
    let furi = '';

    for (let i = 0; i < str.length; i += 1, current = next) {
      next = (i + 1 >= str.length) || Kanji.isKanji(str[i + 1]);
      if (current) { // If it's a kanji :
        this.pushKanji(str[i], furiList ? furiList.shift() : '');
      } else { // If it's an hirgana :
        furi += str[i];
        if (next) {
          this.pushFuri(furi);
          furi = '';
        }
      }
    }

    if (trucquejaijamaisecr) {
      this.meaning = trucquejaijamaisecr;
    }
  }

  pushKanji(kanji: string, furi = ''): void {
    // Push the new Mono into kotoba
    // But also push the reference of the created mono into kanjiList :
    this.kanjiList.push(this.kotoba[this.kotoba.push(new Mono(furi, kanji)) - 1]);
  }

  pushFuri(furi: string): void {
    this.kotoba.push(new Mono(furi));
  }

  size(): number {
    return this.kotoba.length;
  }

  updatedFromFuri(index: number): void {
    if (index < this.kotoba.length) {
      if (!this.kotoba[index].kanji && this.kotoba[index].furi === '') {
        this.kotoba.splice(index, 1);
      }
    }
  }
}
