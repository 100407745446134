import Vue from 'vue';
import VueRouter from 'vue-router';
// Vue :
import Bunpou from '@/components/Bunpou.vue';
import Vocab from '@/components/Vocab.vue';
import MeishiManager from '@/components/Manage/Meishi.vue';
import Manager from '@/components/Manage/Default.vue';
import HomeManager from '@/components/Manage/Home.vue';
import App from '@/App.vue';
import SpeedKana from '@/components/SpeedKana.vue';
import Home from '@/components/Home.vue';

Vue.config.productionTip = false;

const routes = [
  { path: '', component: Home },
  { path: '/bunpou', component: Bunpou },
  { path: '/vocab', component: Vocab },
  { path: '/hiragana', component: SpeedKana, props: { hiragana: true } },
  { path: '/katakana', component: SpeedKana, props: { hiragana: false } },
  {
    path: '/manage',
    component: HomeManager,
    children: [
      {
        path: '',
        component: Manager,
      },
      {
        path: 'meishi',
        component: MeishiManager,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes, // short for `routes: routes`
});

Vue.use(VueRouter);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
