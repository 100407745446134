













































import { Component, Vue, Prop } from 'vue-property-decorator';
import HomeButton from '@/components/small/HomeButton.vue';
import KanaOption from '@/interfaces/KanaOption';
import Hiragana from '@/classes/Hiragana';
import Katakana from '@/classes/Katakana';

@Component({
  components: {
    HomeButton,
  },
})
export default class SpeedKanaVue extends Vue {
  @Prop() hiragana?: boolean;

  options: Array<KanaOption> = [];

  total = 70;

  done = 0;

  queue: Array<string> = [];

  found: Array<{kana: string, date: number}> = [];

  worstList: Array<{kana: string, time: string}> = [];

  currentKana = '';

  answer = '';

  showAnswer = false;

  startedAt = Date.now();

  totalTime: {n: number, s: string} = { n: Date.now(), s: '' };

  hasFocus = false;

  beforeMount(): void {
    if (this.hiragana) {
      this.options = Hiragana.options;
    } else {
      this.options = Katakana.options;
    }
    this.options[0].checked = true;
    this.options[1].checked = true;
    this.options[2].checked = true;
    this.options[3].checked = false;
  }

  swap(index: number): void {
    if (index < this.options.length) {
      this.options[index].checked = !this.options[index].checked;
    }
  }

  startGame(): void {
    this.queue = [];
    this.found = [];
    for (let i = 0; i < this.options.length; i += 1) {
      if (this.options[i].module.length > 0 && this.options[i].checked) {
        this.queue.push(...this.options[i].module);
      }
    }
    this.done = 0;
    this.total = this.queue.length;
    this.genNextKana();
    (this.$refs.input as HTMLElement).focus();
    this.hasFocus = true;
    this.totalTime.n = Date.now();
  }

  genNextKana() {
    if (this.queue.length > 0) {
      const index = Math.floor(Math.random() * this.queue.length);
      [this.currentKana] = this.queue.splice(index, 1);
      this.showAnswer = false;
      if (this.hiragana) {
        this.answer = Hiragana.h2r(this.currentKana);
      } else {
        this.answer = Katakana.k2r(this.currentKana);
      }
      this.startedAt = Date.now();
    } else {
      this.won();
    }
  }

  update(event: any): void {
    // this.updating = true;
    const inputValue = event.target.value;
    // console.log(`${inputValue} === ${this.currentKana} || ${inputValue} === ${this.answer}`);
    // console.log(`${inputValue === this.currentKana} || ${inputValue === this.answer}`);
    if (inputValue === this.currentKana || inputValue === this.answer) { // found
      // Stop timer
      this.found.push({
        kana: this.currentKana,
        date: Date.now() - this.startedAt,
      });
      this.done += 1;
      this.genNextKana();
      (this.$refs.input as HTMLInputElement).value = '';
    }
    // this.updating = false;
  }

  start(): void {
    if (this.options[0].checked || this.options[1].checked || this.options[2].checked) {
      (this.$refs.choose as HTMLElement).style.display = 'none';
      (this.$refs.score as HTMLElement).style.display = 'none';
      (this.$refs.game as HTMLElement).style.display = 'flex';
      this.startGame();
    }
  }

  won(): void {
    this.totalTime.s = this.timeToString(Date.now() - this.totalTime.n);
    (this.$refs.choose as HTMLElement).style.display = 'none';
    (this.$refs.game as HTMLElement).style.display = 'none';
    (this.$refs.score as HTMLElement).style.display = 'flex';
    (this.$refs.input as any).blur();
    // Select the 5 worst :
    this.found.sort((a, b) => b.date - a.date);
    this.worstList = [];
    for (let i = 0; i < 5 && i < this.found.length; i += 1) {
      this.worstList.push({
        kana: this.found[i].kana,
        time: this.timeToString(this.found[i].date),
      });
    }
  }

  timeToString(t: number): string {
    const d = new Date(t);
    let text = '';
    if (d.getMinutes() > 0) {
      text += `${d.getMinutes()}m`;
    }
    text += `${d.getSeconds()}.`;
    const ms = d.getMilliseconds();
    if (ms < 10) {
      text += '00';
    } else if (ms < 100) {
      text += '0';
    }
    text += `${ms}s`;

    return text;
  }

  swapAnswer(): void {
    this.showAnswer = !this.showAnswer;
  }
}
