






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Manager extends Vue {
  @Prop() private word!: string;
}
