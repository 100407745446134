








import Settings from '@/classes/Settings';
import PouchDB from 'pouchdb-browser';
import { Vue, Component } from 'vue-property-decorator';

const dbSettings = new PouchDB('settings');

@Component
export default class App extends Vue {
  settings: Settings = {
    id: '0',
    light: true,
  };

  switchLight(): void {
    this.settings.light = !this.settings.light;
    this.setLight();
  }

  async setLight(): Promise<void> {
    document.documentElement.setAttribute('data-theme', (this.settings.light ? 'light' : 'dark'));
    this.saveSettings();
  }

  async saveSettings(): Promise<void> {
    // get the settings in DB :
    const pastSettings = await dbSettings.get(this.settings.id) as Settings;
    // Set new settings :
    pastSettings.light = this.settings.light;
    // Apply modifications :
    await dbSettings.put(pastSettings);
  }

  async initSettings(): Promise<void> {
    // const request =
    await dbSettings.post(this.settings);
  }

  async beforeMount(): Promise<void> {
    // document.documentElement.setAttribute('data-theme', 'dark');
    let settingsFromDB = await dbSettings.allDocs({
      include_docs: true,
    });

    if (settingsFromDB.total_rows === 0) {
      await this.initSettings();
      settingsFromDB = await dbSettings.allDocs({
        include_docs: true,
      });
    }

    this.settings = (settingsFromDB.rows[0].doc as any) as Settings;
    this.settings.id = settingsFromDB.rows[0].id;
    this.setLight();
  }
}
