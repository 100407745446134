export default class Mono {
  furi: string;

  kanji?: string;

  constructor(furi = '', kanji?: string) {
    this.furi = furi;
    if (kanji) {
      this.kanji = kanji;
    }
  }
}
