export default class Meaning {
  language = '';

  prevName = '';

  meanings: Array<string> = [];

  constructor(name: string, mngs: Array<string> = []) {
    this.language = name;
    this.meanings = mngs;
    while (this.meanings.length < 3) {
      this.meanings.push('');
    }
  }

  public get name(): string {
    return this.language;
  }

  public set name(newName: string) {
    this.prevName = this.language;
    this.language = newName;
  }
}
