
import { Component, Vue } from 'vue-property-decorator';
import Meishi from '@/classes/Meishi';
import MeaningList from '@/classes/MeaningList';
import Meaning from '@/classes/Meaning';
import { JsonKotoba } from '@/classes/JsonKotoba';
import meishiFile from '@/data/meishi.json';
import HomeButton from '@/components/small/HomeButton.vue';

@Component({
  components: {
    HomeButton,
  },
})
export default class MeishiManager extends Vue {
  meishiList: any; // : Array<JsonKotoba> = [];

  meishi = new Meishi();

  meaning: MeaningList = new MeaningList();

  groups: Array<string> = [];

  searchPrevKanji = '';

  create = true;

  public get kanjiText(): string {
    return this.meishi.getStr();
  }

  public set kanjiText(newText: string) {
    this.meishi.setFromStr(newText);
  }

  goUp(event: any): void {
    switch (event.target.name) {
      case 'furInput':
        (this.$refs.kanjInput as HTMLElement).focus();
        break;
      default:
    }
  }

  goDown(event: any): void {
    switch (event.target.name) {
      case 'kanjInput':
        this.focusEl(this.$refs.furInput as HTMLElement[]);
        break;
      case 'furInput':
        break;
      default:
    }
  }

  focusEl(input: HTMLElement[]): void {
    input[0].focus();
  }

  load(loadedMeishi: JsonKotoba): void {
    const meanings = new MeaningList();
    for (let i = 0; i < loadedMeishi.language.length; i += 1) {
      meanings.add(new Meaning(loadedMeishi.language[i].name, loadedMeishi.language[i].meaning));
    }
    this.meishi.setKotobaFromStr(loadedMeishi.kanji, [...loadedMeishi.furi], meanings);
    this.create = false;
  }

  furiUpdated(index: number, event: any): void {
    this.meishi.updatedFromFuri(index);
    const copy = event.target;
    const newWidth = event.target.value.length * 2;
    copy.style.width = `${newWidth >= 2 ? newWidth : 2}rem`;
  }

  addLanguage(): void {
    this.meishi.meaning.addDefault();
  }

  addGroup(): void {
    this.groups.push('');
  }

  removeGrp(index: number): void {
    if (index < this.groups.length) {
      this.groups.splice(index, 1);
    }
  }

  searchKanji(): void {
    const kanji = (this.$refs.kanjInput as any).value;
    if (kanji !== this.searchPrevKanji) {
      const meishi = this.meishiList[kanji] as JsonKotoba;
      this.create = !(meishi);
      if (meishi) {
        this.load(meishi);
      }
    }
    this.searchPrevKanji = kanji;
  }

  changeLanguage(index: number, ln: string): void {
    for (let i = 0; i < this.meishi.meaning.avaiLang.length; i += 1) {
      if (this.meishi.meaning.avaiLang[i] === ln) {
        this.meishi.meaning.avaiLang[i] = this.meishi.meaning.languages[index].prevName;
        break;
      }
    }
  }

  responsiveInput(event: any): void {
    const copy = event.target;
    if (copy.value.length <= 12) {
      copy.style.width = '30%';
    } else if (copy.value.length <= 24) {
      copy.style.width = '60%';
    } else {
      copy.style.width = '100%';
    }
  }

  async beforeMount(): Promise<void> {
    this.meishiList = meishiFile;
  }
}
