import katakanaFile from '@/data/katakana.json';

export default class Katakana {
  public static main = [
    'ア', 'イ', 'ウ', 'オ', 'ウ',
    'カ', 'キ', 'ク', 'コ', 'ク',
    'サ', 'シ', 'ス', 'セ', 'ソ',
    'タ', 'チ', 'ツ', 'テ', 'ト',
    'ナ', 'ニ', 'ヌ', 'ネ', 'ノ',
    'ハ', 'ヒ', 'フ', 'ヘ', 'ホ',
    'マ', 'ミ', 'ム', 'メ', 'モ',
    'ヤ', 'ユ', 'ヨ',
    'ラ', 'リ', 'ル', 'レ', 'ロ',
    'ワ', 'ヲ', 'ン',
  ];

  public static maru = ['パ', 'ピ', 'プ', 'ペ', 'ポ'];

  public static tenten = [
    'ガ', 'ギ', 'グ', 'ゲ', 'ゴ',
    'ザ', 'ジ', 'ズ', 'ゼ', 'ゾ',
    'ダ', 'ヂ', 'ヅ', 'デ', 'ド',
    'バ', 'ビ', 'ブ', 'ベ', 'ボ',
  ];

  public static options = [
    {
      title: 'Core',
      checked: true,
      text: Katakana.main.join(''),
      big: true,
      module: Katakana.main,
    },
    {
      title: 'Dakuten',
      checked: true,
      text: Katakana.tenten.join(''),
      big: false,
      module: Katakana.tenten,
    },
    {
      title: 'Handakuten',
      checked: true,
      text: Katakana.maru.join(''),
      big: false,
      module: Katakana.maru,
    },
    {
      title: 'Easy mode',
      checked: false,
      text: 'Show the answer if you click onto the katakana',
      big: false,
      module: [],
    },
  ];

  public static k2r(kata: string): string {
    return (katakanaFile as any)[kata];
  }
}
