import hiraganaFile from '@/data/hiragana.json';

export default class Hiragana {
  public static main = [
    'あ', 'い', 'う', 'え', 'お',
    'か', 'き', 'く', 'け', 'こ',
    'さ', 'し', 'す', 'せ', 'そ',
    'た', 'ち', 'つ', 'て', 'と',
    'な', 'に', 'ぬ', 'ね', 'の',
    'は', 'ひ', 'ふ', 'へ', 'ほ',
    'ま', 'み', 'む', 'め', 'も',
    'や', 'ゆ', 'よ',
    'ら', 'り', 'る', 'れ', 'ろ',
    'わ', 'を', 'ん',
  ];

  public static maru = ['ぱ', 'ぴ', 'ぷ', 'ぺ', 'ぽ'];

  public static tenten = [
    'が', 'ぎ', 'ぐ', 'げ', 'ご',
    'ざ', 'じ', 'ず', 'ぜ', 'ぞ',
    'だ', 'ぢ', 'づ', 'で', 'ど',
    'ば', 'び', 'ぶ', 'べ', 'ぼ',
  ];

  public static options = [
    {
      title: 'Core',
      checked: true,
      text: Hiragana.main.join(''),
      big: true,
      module: Hiragana.main,
    },
    {
      title: 'Dakuten',
      checked: true,
      text: Hiragana.tenten.join(''),
      big: false,
      module: Hiragana.tenten,
    },
    {
      title: 'Handakuten',
      checked: true,
      text: Hiragana.maru.join(''),
      big: false,
      module: Hiragana.maru,
    },
    {
      title: 'Easy mode',
      checked: false,
      text: 'Show the answer if you click onto the hirgana',
      big: false,
      module: [],
    },
  ];

  public static h2r(hira: string): string {
    return (hiraganaFile as any)[hira];
  }
}
